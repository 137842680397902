import React from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Remove from '@mui/icons-material/Remove';
import Cancel from '@mui/icons-material/Cancel';
import Bolt from '@mui/icons-material/Bolt';
import { green, red } from '@mui/material/colors';
import { TwoActionsButtonsCell } from '@fingo/lib/components/cells';
import Avatar from '@mui/material/Avatar';
import { requestingPlatformMapperImage } from '@fingo/lib/assets';

export const isRowSelectable = ({ row }) => !Object.values(
  row.offer.reviewDocumentsManager,
).includes('PENDINGREVIEW');

export const renderCell = ({ row }, docType, handleStatusChange, resolutions) => {
  const res = resolutions[row.offer.id] ? resolutions[row.offer.id][docType] : 'PENDING';
  switch (res) {
    case 'ACCEPTED':
      return <CheckCircle sx={{ color: green[700] }} />;
    case 'REJECTED':
      return <Cancel sx={{ color: red[700] }} />;
    default:
      switch (row.offer.reviewDocumentsManager[docType]) {
        case 'NOTREQUIRED':
          return <Remove />;
        case 'ACCEPTED':
          return <CheckCircle sx={{ color: green[700] }} />;
        case 'REJECTED':
          return <Cancel sx={{ color: red[700] }} />;
        default:
          return (
            <TwoActionsButtonsCell
              onAccept={() => handleStatusChange(row, docType, 'ACCEPTED')}
              onReject={() => handleStatusChange(row, docType, 'REJECTED')}
            />
          );
      }
  }
};

export const ratificationDocumentsReviewInvoiceAlerts = (invoice) => {
  const OCAlertsArray = [
    {
      value: invoice.lightningPaymentAuthorized,
      label: 'lightning-payment-authorized',
      color: green[500],
      tooltip: `Portal de proveedores: ${invoice.receiver.name}. Factura autorizada para pago rápido`,
      icon: <Bolt sx={{ width: 12, height: 12 }} />,
    },
  ];
  if ('operationalRoute' in invoice && invoice.operationalRoute !== '77201556') {
    OCAlertsArray.push(
      {
        value: invoice.operationalRoute,
        label: 'externa',
        tooltip: 'Factura evaluada externamente',
        icon: <Avatar
          src={requestingPlatformMapperImage[invoice.operationalRoute]}
          sx={{ width: 15, height: 15 }}
        />,
      },
    );
  }
  return OCAlertsArray;
};
