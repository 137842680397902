import React from 'react';
import {
  Checkbox,
  FormGroup,
  FormControl,
  Input,
  FormControlLabel,
  InputAdornment,
  Select,
  MenuItem,
} from '@mui/material';
import RejectReasonDialog from '../components/risk-evaluations/RejectReasonDialog';
import RiskEvaluationBlacklist from '../components/risk-evaluations/RiskEvaluationBlacklist';

const ratificationReasons = [
  { label: 'Directa a giro línea deudor', value: 'WITHOUT_RESTRICTION_DEBTOR' },
  { label: 'Directa a giro línea cliente', value: 'WITHOUT_RESTRICTION_CLIENT' },
  { label: 'Con revisión de documentos línea deudor', value: 'WHITHOUT_RATIFICATION_DEBTOR' },
  { label: 'Con revisión de documentos línea cliente', value: 'WHITHOUT_RATIFICATION_CLIENT' },
  { label: 'Ratificación obligatoria', value: 'WITH_RATIFICATION' }];

const withoutDocumentsReview = [
  'WITH_RATIFICATION',
  'WITHOUT_RESTRICTION_DEBTOR',
  'WITHOUT_RESTRICTION_CLIENT',
];

const documentsReview = [
  { label: 'Requiere OC', name: 'reviewOc' },
  { label: 'Requiere HES', name: 'reviewHes' },
  { label: 'Requiere pantallazo de portal', name: 'reviewPortalScreenshot' },
  { label: 'Acuse de recibo', name: 'hasReceptionAcknowledgment' },
];

const riskEvaluationAcceptRejectColumns = (
  updatePreoffer,
  setOpenOtherRejectionReason,
  setSelectedPreOfferEvaluation,
) => [
  {
    field: 'orderingMonthlyRate',
    headerName: 'Tasa Ordering Interes 30 días',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <FormControl variant="outlined">
        <Input
          name="orderingMonthlyRate"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={row.orderingMonthlyRate}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        />
      </FormControl>
    ),
  },
  {
    field: 'orderingDefaultRate',
    headerName: 'Tasa Mora 30 días',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <FormControl variant="standard">
        <Input
          name="orderingDefaultRate"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={row.orderingDefaultRate}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        />
      </FormControl>
    ),
  },
  {
    field: 'orderingRetentionRate',
    headerName: 'Tasa Retención',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <FormControl variant="standard">
        <Input
          name="orderingRetentionRate"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={row.orderingRetentionRate}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        />
      </FormControl>
    ),
  },
  {
    field: 'monthlyRate',
    headerName: 'Tasa Interes 30 días',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <FormControl variant="outlined">
        <Input
          name="monthlyRate"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={row.monthlyRate}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        />
      </FormControl>
    ),
  },
  {
    field: 'defaultRate',
    headerName: 'Tasa Mora 30 días',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <FormControl variant="standard">
        <Input
          name="defaultRate"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={row.defaultRate}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        />
      </FormControl>
    ),
  },
  {
    field: 'retentionRate',
    headerName: 'Tasa Retención',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <FormControl variant="standard">
        <Input
          name="retentionRate"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={row.retentionRate}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        />
      </FormControl>
    ),
  },
  {
    field: 'fundingDays',
    headerName: 'Financiamiento',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 0.4,
    renderCell: ({ row }) => (
      <FormControl variant="standard">
        <Input
          name="fundingDays"
          endAdornment={<InputAdornment position="end">días</InputAdornment>}
          value={row.fundingDays}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        />
      </FormControl>
    ),
  },
  {
    field: 'ratification',
    headerName: 'Ratificación',
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    flex: 0.5,
    renderCell: ({ row }) => (
      <FormControl fullWidth>
        <Select
          name="ratification"
          value={row.ratification}
          onChange={(event) => { updatePreoffer(row.id, event, 'value'); }}
        >
          {ratificationReasons.map((type) => (
            <MenuItem value={type.value}>{type.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  },
  {
    field: 'rejectionReason',
    headerName: 'Razón Rechazo',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <RejectReasonDialog
        row={row}
        updatePreoffer={updatePreoffer}
        setOpenOtherRejectionReason={setOpenOtherRejectionReason}
        setSelectedPreOfferEvaluation={setSelectedPreOfferEvaluation}
      />
    ),
  },
  {
    field: 'documentsReview',
    headerName: 'Revisión Documentos',
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueGetter: ({ row }) => documentsReview.map((document) => row[document.name]),
    renderCell: ({ row }) => (
      <FormGroup>
        {documentsReview.map((document) => (
          <FormControlLabel
            key={document.label}
            disabled={withoutDocumentsReview.includes(row.ratification)}
            control={(
              <Checkbox
                checked={row[document.name]}
                name={document.name}
                onChange={(event) => {
                  updatePreoffer(row.id, event, 'checked');
                }}
              />
            )}
            label={document.label}
          />
        ))}
      </FormGroup>
    ),
  },
  {
    field: 'receiverBlacklist',
    headerName: 'Agregar Deudor',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <RiskEvaluationBlacklist
        companyId={row.receiver?.id || row.purchaser?.id}
        companyName={row.receiver?.name || row.purchaser?.name}
      />
    ),
  },

];

export default riskEvaluationAcceptRejectColumns;
