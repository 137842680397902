import React from 'react';
import RiskEvaluationNotesActions from '@fingo/lib/components/drawer/RiskEvaluationNotesActions';
import RiskEvaluationPastEvaluation from '@fingo/lib/components/drawer/RiskEvaluationPastEvaluations';
import RiskEvaluationPastEvaluationOrdering from '@fingo/lib/components/drawer/RiskEvaluationPastEvaluationsOrdering';
import RiskVariables from '../components/risk-evaluations/RiskVariables';
import RiskResults from '../components/risk-evaluations/RiskResults';
import RiskReExecute from '../components/risk-evaluations/RiskReExecute';
import AutomaticEvaluation from '../components/risk-evaluations/AutomaticEvaluation';
import RiskPredictionDialog from '../components/risk-evaluations/RiskPredictionDialog';
import SLAComponent from '../components/risk-evaluations/SLAComponent';

const riskEvaluationsDocumentColumns = (documentType) => ([
  {
    field: 'sla',
    headerName: 'SLA',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => <SLAComponent row={row} documentType={documentType} showTooltip />,
    flex: 1,
  },
  {
    field: 'riskVariables',
    headerName: 'V. Riesgo',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => {
      const receiverType = documentType === 'invoice' ? 'receiver' : 'purchaser';
      return (
        <RiskVariables
          riskEvaluation={row.riskEvaluationModel}
          documentId={row.id}
          documentType={documentType}
          client={row.company}
          receiver={row[receiverType]}
        />
      );
    },
    flex: 0.5,
  },
  {
    field: 'riskPredictionResults',
    headerName: 'Modelo Sugerencia',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => (
      <RiskPredictionDialog
        riskEvaluationId={row.riskEvaluationModel?.id}
      />
    ),
    flex: 0.5,
  },
  {
    field: 'automaticEvaluation',
    headerName: 'Modelo',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => (
      <AutomaticEvaluation invoice={row} />
    ),
    flex: 0.5,
  },
  {
    field: 'riskResults',
    headerName: 'R. Arbol | RAS',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => (
      <RiskResults
        riskEvaluation={row.riskEvaluationModel}
        documentId={row.id}
        documentType={documentType}
      />
    ),
    flex: 0.5,
  },
  {
    field: 'reExecute',
    headerName: 'Re-ejecutar',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => (
      <RiskReExecute documentId={row.id} documentType={documentType} />
    ),
    flex: 0.5,
  },
  {
    field: 'notes',
    headerName: 'Notas',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    renderCell: ({ row }) => (
      <RiskEvaluationNotesActions
        document={row}
      />
    ),
    flex: 0.2,
  },
  {
    field: 'evaluations',
    headerName: 'Evaluaciones',
    type: 'singleSelect',
    align: 'center',
    sortable: false,
    width: 90,
    renderCell: ({ row }) => {
      if (documentType === 'invoice') {
        return (
          <RiskEvaluationPastEvaluation invoiceId={row.id} />
        );
      }
      return <RiskEvaluationPastEvaluationOrdering purchaseOrderId={row.id} />;
    },
  },
]);

export default riskEvaluationsDocumentColumns;
