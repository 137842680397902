import { gql } from '@apollo/client';
import { CompanyDocumentsFields, MasterEntityBasicField } from '@fingo/lib/graphql/customers/fragment';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';

const ExecutiveAssignedPendingDocumentsReviewFields = gql`
  fragment ExecutiveAssignedPendingDocumentsReviewFields on UserType {
    id
    firstName
    lastName
    picture {
      name
      url
    }
    completeName @client
  }
`;

export const InvoicePendingDocumentsReviewFields = gql`
  fragment InvoicePendingDocumentsReviewFields on InvoiceType {
    id
    folio
    siiStatus
    receptionDate
    operationalRoute
    hasExecutiveMerit @client
    daysToExecutiveMerit @client
    amountWithIva {
      ...MoneyFields
    }
    lightningPaymentAuthorized @client
    historystatusSet {
      id
      createdAt
      updatedAt
      status
    }
  }
  ${MoneyFields}
`;

export const UniqueDocumentFinanceStatePendingDocumentsReviewFields = gql`
  fragment UniqueDocumentFinanceStatePendingDocumentsReviewFields on DocumentFinanceStateType {
    id
    eventTime: date
    paymentDate
    rejectionCode {
      id
      reason
    }
    status {
      id
      code
      status
    }
  }
`;

export const CompanyPendingDocumentsReviewFields = gql`
  fragment CompanyPendingDocumentsReviewFields on CompanyType {
    id
    documents {
      ...CompanyDocumentsFields
    }
    executiveAssigned {
      ...ExecutiveAssignedPendingDocumentsReviewFields
    }
    masterEntity {
      ...MasterEntityBasicField
      riskBlacklist {
        id
      }
    }
  }
  ${CompanyDocumentsFields}
  ${MasterEntityBasicField}
  ${ExecutiveAssignedPendingDocumentsReviewFields}
`;

export const RecieverPendingDocumentsReviewFields = gql`
  fragment RecieverPendingDocumentsReviewFields on MasterEntityType {
    ...MasterEntityBasicField
    riskBlacklist {
      id
    }
  }
  ${MasterEntityBasicField}
`;

export const ReviewDocumentsManagerFields = gql`
  fragment ReviewDocumentsManagerFields on ReviewDocumentsManagerType {
    id
    requiresOc
    requiresHes
    requiresPortalScreenshot
    ocReviewState
    hesReviewState
    portalScreenshotReviewState
    createdAt
  }
`;
