const chileColumns = [
  'name',
  'company_ExecutiveAssigned__firstName',
  'evaluationRequest_AssignedEvaluator_FirstName',
  'numberEvaluations',
  'numberDebtors',
  'sla',
  'companyInvoiceInEvaluationAmountSum',
  'evaluationRequest_FirstCreated',
  'evaluationRequest_LastCreated',
  'clientDocuments',
];

const mexicoColumns = [
  'name',
  'company_ExecutiveAssigned__firstName',
  'evaluationRequest_AssignedEvaluator_FirstName',
  'numberEvaluations',
  'numberDebtors',
  'companyInvoiceInEvaluationAmountSum',
  'evaluationRequest_FirstCreated',
  'evaluationRequest_LastCreated',
  'clientDocuments',
];

const RiskEvaluationColumnsByCountry = {
  Chile: chileColumns,
  Mexico: mexicoColumns,

};

export default RiskEvaluationColumnsByCountry;
